<template>

  <Template1Nav></Template1Nav>
  <slot></slot>
  <Template1Footer></Template1Footer>
</template>
<script setup lang="ts">
useHead({
    titleTemplate: 'template 1 - %s'
  })



const { fetchGlobalData } = useGlobalStore();
onMounted(fetchGlobalData);

</script>